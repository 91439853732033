/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-1",
    "aws_cognito_identity_pool_id": "us-west-1:9b498408-22d2-4e33-8198-5ac0d1a7befe",
    "aws_cognito_region": "us-west-1",
    "aws_user_pools_id": "us-west-1_045Ednb5c",
    "aws_user_pools_web_client_id": "6t4ffij0et872rom94ql0ibt4r",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://zuiskenq7rfr7azijjrvzu33ai.appsync-api.us-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "bhpmjsaa65d4d2254e4b41a89df0d66c611dc0144251-prod",
    "aws_user_files_s3_bucket_region": "us-west-1"
};


export default awsmobile;
